import React from 'react';
import { connect } from 'react-redux';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import LanguageSwitch from '../LanguageSwitch';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import CurrencySwitch from '../CurrencySwitch';
import { Helmet } from 'react-helmet-async';
import * as queryString from "query-string";

const Header = ({ dir, wl, /*lng*/ }) => {
  // console.log('render header')
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const rtl = dir === 'rtl';
  const lng = i18n.language.substring(0, 2)

  return (
    <Navbar bg="white" className="header" dir={rtl ? 'rtl' : 'ltr'} expand="false">
      <MyHelmet lng={lng}></MyHelmet>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <span className="d-lg-none">
        {wl ? (<span className='text-muted'>{t("poweredBy")} </span>): (<span></span>)}
        {t("brand")}
      </span>
      <span>
        <span className="d-none d-lg-inline">
          {wl ? (<span className='text-muted'>{t("poweredBy")} </span>): (<span></span>)}
          {t("brand")}
        </span>&nbsp;&nbsp;<a href="/"><img src="/favicon.ico" className='rounded' style={{ height: "43px" }} alt="paapmpaapm logo"></img></a>
      </span>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav.Link className="text-justify" href="/">{t('nav-search-engine')}</Nav.Link>
        {/* <Nav.Link className="text-justify" href="/">{t('nav-rent-abroad')}</Nav.Link> */}
        <Nav.Link className="text-justify" href="https://paapmpaapm.com/">{t('nav-price-cut')}</Nav.Link>
        <span className="nav-link"><LanguageSwitch /></span>
        <span className="nav-link"><CurrencySwitch /></span>
    </Navbar.Collapse>
  </Navbar>
  )
}

function MyHelmet(props) {
  // adding lang part of the url if not already set
  const strippedPath = window.location.pathname.replace(/^\/(en|he|ar)/i, "")
  let location = window.location.origin + `/${props.lng}` + strippedPath;

  const queryParams = queryString.parse(window.location.search);
  // remove any affiliate and wl params for canonical
  delete queryParams["wl"]
  delete queryParams["partner"]
  delete queryParams["utm_campaign"]
  delete queryParams["utm_source"]
  delete queryParams["utm_medium"]
  delete queryParams["lng"]

  const canonical = queryParams && Object.keys(queryParams).length > 0 ? `${location}?${queryString.stringify(queryParams)}` : `${location}`

  const alternates = []
  Array("en", "he", "ar").forEach(language => {
    alternates.push(
      <link rel="alternate" href={`${window.location.origin}/${language}${strippedPath}`} hreflang={language} key={language} />
    )
  });

  const { t } = useTranslation();
  const params = {};
    params[
      "title"
    ] = t('headTitle')
    params[
      "og:title"
    ] = t('headTitle')
    params[
      "description"
    ] = t('headDescription')
    params[
      "og:description"
    ] = t('headDescription')

  return (
    <Helmet>
      <title>{params["title"]}</title>
      <meta property="og:title" content={params["og:title"]}></meta>
      <meta property="og:description" content={params["og:description"]}></meta>
      <meta name="description" content={params["description"]}></meta>
      <link rel="canonical" href={canonical} />
      <html lang={props.lng}></html>
      {alternates}
    </Helmet>
  );
}

const mapStateToProps = ({ vendors, results, ui, currencies }) => ({
  dir: ui.direction,
  wl: ui.whiteLabel,
  // lng: ui.language,
});

export default connect(mapStateToProps)(Header);